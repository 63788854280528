/**
 * Dashboard
 */

import React, { Component, Fragment } from 'react'
import { Alert } from 'reactstrap';
import { Helmet } from "react-helmet";
import { Redirect, Route, Switch, Link } from 'react-router-dom';
import { Form, FormGroup, Input } from 'reactstrap';
import FormControl from '@material-ui/core/FormControl';
import MatButton from '@material-ui/core/Button';
// intl messages
import IntlMessages from 'Util/IntlMessages';
import Button from '@material-ui/core/Button';
import { format, parseISO, addHours} from 'date-fns';
import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import { NotificationManager } from 'react-notifications';
// app config
import { connect } from 'react-redux';
// page title bar
import PageTitleBar from 'Components/PageTitleBar/PageTitleBar';

// rct collapsible card
import RctCollapsibleCard from 'Components/RctCollapsibleCard/RctCollapsibleCard';
// rct section loader
import RctSectionLoader from 'Components/RctSectionLoader/RctSectionLoader';
import { RctCard, RctCardContent } from 'Components/RctCard';
import { DateTimePicker, DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import { es } from "date-fns/locale";
import DateFnsUtils from '@date-io/date-fns'; // choose your lib
import esLocale from "date-fns/locale/es";

import {
	VisitorAreaChartWidget,
	SalesAreaChartWidget,
	OrdersAreaChartWidget,
	RecentOrdersWidget,
	SupportRequest,
	Notifications,
	TopSellingWidget,
	OverallTrafficStatusWidget,
	ProductReportsWidget,
	OnlineVisitorsWidget,
	TodayOrdersStatsWidget,
	BookingInfo,
	NewOrderCountdown,
	FollowersWidget,
	Notes
} from "Components/Widgets";

// widgets data
import {
	visitorsData,
	salesData,
	ordersData,
	topSellingProducts,
	trafficStatus,
} from './data';

import axios from "axios";

import { Bar, Line } from 'react-chartjs-2';
import ChartConfig from 'Constants/chart-config';
import { Badge } from 'reactstrap';
import MatBadge from '@material-ui/core/Badge';

class Dashboard extends Component {
        
        constructor(props) {
      
        super(props);
            this.state = {
              ready: true,
              visible: true,
              loading: true,
              selectedDate: new Date(),
              last_activity: '',
              maxDate : new Date(),
              minDate: new Date(new Date().getFullYear() - 4, new Date().getMonth(), new Date().getDate()),
              myRol : ''
            };
            this.onDismiss = this.onDismiss.bind(this);
        };
        
        onDismiss() {
            this.setState({ visible: false });
         }
   
         componentWillMount() {

            const { location, history, darkMode} = this.props;
            
            axios.get('/checkloggin', {}).then((response) => {
              if(!response.data){
                  if (localStorage.getItem("user_id") !== null) {
                        localStorage.removeItem('user_id');
                        localStorage.removeItem('name');
                        localStorage.removeItem('email');
                        localStorage.removeItem('rol');
                        localStorage.removeItem('rut');
                        history.push('/signin');
                        NotificationManager.error('¡Se ha perdido la sesión!');
                    }
              } else {
                  this.setState({ myRol:response.data.rol});
                  this.Init();
              }
            }, (error) => {
                console.log(error);

            });

        }
        
        Init(){
            
            this.setState({ loading:true });

            axios.get(`/users/last-activity`)
               .then(res => new Promise(resolve => setTimeout(() => resolve( 
             this.setState({ loading:false, last_activity : res.data[0].last_active_at })), 0)));

            
        }

         componentDidMount(){

         }

	render() {
                const {visible, selectedDate, loading, last_activity, myRol} = this.state;
		const { match, darkMode } = this.props;
       
                const isDesktop = window.innerWidth > 768;
                
		return (
                        
			<div className="ecom-dashboard-wrapper">
                                {loading  && 
                                    <RctSectionLoader />
				}
				<Helmet>
					<title>{process.env.MIX_APP_NAME} | Dashboard</title>
					<meta name="description" content="Dashboard" />
				</Helmet>
				<PageTitleBar title={<IntlMessages id="sidebar.dashboard" />} match={match} />
                                {!loading &&
                                <Fragment>
                                <Alert className="d-flex align-items-center" color="success" isOpen={this.state.visible} toggle={this.onDismiss}>
                                <i className="zmdi zmdi-check check-circle"></i> Última actividad registrada: {format(addHours(parseISO(last_activity),1), 'dd/MM/yyyy')} a las {format(addHours(parseISO(last_activity),1), 'H:mm')}
                                </Alert>
                                
                                 <Accordion  className="mb-30 panel" defaultExpanded>
                                        <AccordionSummary expandIcon={<i className="zmdi zmdi-chevron-down"></i>} className="m-0 panel-heading">
                                                <h4>Bienvenido</h4>
                                        </AccordionSummary>
                                        <AccordionDetails>
                                                <p>
                                                       Sistema de Checklist para Transbenumapu, si detecta o experimenta algún problema favor enviar un mail a pablo.cruz@aysentf.cl o nikosk8r@gmail.com, o contáctese 
                                                       al número +56 9 88306126
                                                </p>
                                        </AccordionDetails>
                                </Accordion>
                                
                                <div className="row">
					
				<RctCollapsibleCard
                                        customClasses=""
                                        colClasses="col-sm-12 col-md-4 col-lg-4 w-xs-full"
                                        heading='Enlaces Rápidos'
                                        fullBlock
                                     >
                                    <RctCardContent>
                                        <FormControl  fullWidth > 
                                            <FormGroup>
                                                <MatButton  component={Link} to="/app/checklist/new" variant="contained" className="btn btn-block btn-warning mr-10 mb-10 text-white w-100">Ingresar Checklist</MatButton>
                                           </FormGroup>
                                           {myRol == 'administrador' &&
                                           <FormGroup>
                                                <MatButton component={Link} to="/app/users/user-management" variant="contained" className="btn btn-block btn-secondary mr-10 mb-10 text-white w-100">Gestión de Usuarios</MatButton>
                                            </FormGroup>
                                            }
                                        </FormControl>
                                     </RctCardContent>
                                    </RctCollapsibleCard>
                                </div>
                                </Fragment>    
                                         
                                }
				
                        </div>
                     
		);
              }   
}

const mapStateToProps = ({ settings }) => {
	return settings;
};

export default connect(mapStateToProps, {})(Dashboard);
